const lng=document.documentElement.lang;
const data_url="1CJKjC9LghPoSP_JjrpntFkKbiWgMDuFzWOW9H8mAXVg";
let params={};
const searchParams = new URLSearchParams(window.location.search);
for (const [key,value] of searchParams.entries()) 
    params[key]=value;

let data={info:null,works:null,videos:null,media:null};

function to_json(data)
{
	let res=[];
	if(data)
	{
		if(data[0][0].charAt(0)=="#")
		{
			res={};
			for(let a=1;a<data.length;a++)
				res[data[a][0]]=data[a][1];
		}else{
		    for(let a=1;a<data.length;a++)
			{
				d={};
        		for(let b=0;b<data[0].length;b++)
        		{
            		d[data[0][b].toLowerCase()]=data[a][b];
        		}				
				res.push(d);
			}
		}
	}
    return(res);
}

async function load_data(document,spreadsheet)
{
	try
	{
    	const url = "https://sheets.googleapis.com/v4/spreadsheets/" + document + "/values/"+
                spreadsheet+"?alt=json"+"&key=AIzaSyD8c-oT4E9kvfAvrgUFOsm0b9wdDYOEI34";
    	const res=await fetch(url);
    	const data=await res.json();  
		return(to_json(data.values));
	}catch(e)
	{
		return(null);
	}
}

function cookie_control()
{
	const cookies=document.querySelector(".cookies_msg");
	if(cookies)	
	{
		if(localStorage["cookies_francescabria_site"]!=1)
		{
			cookies.classList.remove("hide");
			cookies.querySelector(".cookies_accept").addEventListener("click",e=>
			{
				e.preventDefault();
				cookies.classList.add("hide");
				localStorage["cookies_francescabria_site"]=1;
			});
		}	
	}		
}

function update_url(url)
{
    return(url.replace("www.dropbox.com","dl.dropboxusercontent.com"));
}
async function load()
{		
	cookie_control();

	if(document.getElementById("main_header"))
		document.querySelector(".header_dark_gradient").style.opacity=1;
	
	const nav=document.querySelector("nav");
	if(nav.classList.contains("scroll"))
	{
		window.addEventListener("scroll",(event)=>
		{
			if(window.scrollY>100)
				nav.classList.add("dark");
			else 
				if(nav.classList.contains("dark")) nav.classList.remove("dark");
		});
	}

	
	if((document.getElementById("home")) || (!localStorage.data))
	{
		//console.log("Loading: ",data_url);
		for(key in data)
		{
			data[key]=await load_data(data_url,key);
			callbacks["show_"+key](data[key],key);
			//console.log(key+" loaded");
		}
		if(data.info && data.works && data.videos && data.media)
		{
			localStorage.data=JSON.stringify(data);
			//console.log("Data loaded: ",data);
		}else{
			alert("Error loading data");
		}
		return;
	}

	if(localStorage.data)
	{
		data=JSON.parse(localStorage.data);

		for(key in data)
		{
			callbacks["show_"+key](data[key],key);
		}
	}	

	
	
	return;
	

	if($("#video_iframe").length)
	{
		
		var spreadsheetID = "17wEFMrA3vUFCupBlRcbIB76ueLa4URb0OxMkl5LQHMY";
 		//var url = "https://spreadsheets.google.com/feeds/list/" + spreadsheetID + "/2/public/values?alt=json";
 		var url = "https://sheets.googleapis.com/v4/spreadsheets/" + spreadsheetID + "/values/"+"Videos"+"?alt=json"+
		 "&key=AIzaSyD8c-oT4E9kvfAvrgUFOsm0b9wdDYOEI34";
		 load_json(url,function (data) {
			 var url=data.values[1][5];
			 $("#video_iframe").attr("src",url.replace("watch?v=","embed/"));
			 list_talks(".video_list",data.values)
			 $(".video_list").find(".talk_info").click(function()
			 {
				$("#video_iframe").attr("src",$(this).attr("aria-url"));
			 })
		});
	} 	 


	if($(".nav_white").length)
	{
		$(".nav_main_menu").addClass("show");
	}
	var scroll=$(".nav_include").attr("aria-scroll");
	if(scroll==0)
	{
		 scroll=$(window).height()-100;	
		$(window).scroll(function(){	

		if($(window).scrollTop()>0) //10)
		{
			$(".header_row").addClass("header_margin");
            $(".about_text").addClass("about_margin");   
		}else{
			$(".header_row").removeClass("header_margin");
            $(".about_text").removeClass("about_margin");   
		}		
	    if($(window).scrollTop()<=scroll) //10)
            $("nav").removeClass("navbar_background");   
        else
            $("nav").addClass("navbar_background");   
 	 	});
	}else $("nav").addClass("navbar_background");   
 

	$(".nav-link").click(function(event)
	{		
		if($("#home").length)
		{
			if(!$(this).attr("href-index")) return(true);   			
			var target=$(this).attr("href-index");   			
			event.preventDefault();
			$('html, body').stop().animate({
         		scrollTop: $(target).offset().top-40
      	},1000);
      }else{
			return(true);
      }
	});

	if($(".nav_btn_folder").length)
	{
		$(".nav_btn_folder").click(function()
		{
			if($(".header_menu").hasClass("main_menu_full"))
				$(".header_menu").removeClass("main_menu_full");
			else
				$(".header_menu").addClass("main_menu_full");
			return(false);
		});
	}
}

const callbacks={
	show_works:function(data,type)
	{
		document.querySelectorAll(`[aria-load="${type}"]`).forEach(el=>
			{	
				const high=el.getAttribute("aria-highfield");
				const child=el.getAttribute("aria-childfield");
				
				const from=document.querySelector("#elements ."+el.getAttribute("aria-item"));
					
				for(let a=0;a<data.length;a++)
				{
					if((!high) || ((high) && (data[a][high]==1)))
					{
						const item=from.cloneNode(true);
						item.querySelectorAll("[aria-field]").forEach(subitem=>{
							const field=subitem.getAttribute("aria-field");
							if(subitem.hasAttribute("aria-select"))
							{
	
							}else{
								if(subitem.tagName==="IMG")
								{
									subitem.src=update_url(data[a][field]);
								}else{
									if(field)
										subitem.innerHTML=data[a][field];
									if(subitem.hasAttribute("href"))
										subitem.setAttribute("href",data[a][subitem.getAttribute("href")]);
								}
							}
						});
	
						if(child)
						{
							const child_node=el.querySelector(`[aria-child="${data[a][child]}"]`);
							if(child_node) child_node.append(item);
						}else
							el.append(item);
					}
				}

			});
	},
	show_media:function(data,type)
	{
		this.show_works(data,type);
	},
	show_videos:function(data,type)
	{
		this.show_works(data,type);

		if(document.querySelector(".video_list"))
		{
			document.querySelector(".video_list").querySelectorAll(".video_item").forEach(el=>{
				el.addEventListener("click",e=>{
					e.preventDefault();
					document.getElementById("video_iframe").setAttribute("src",
						el.querySelector("[aria-url]").innerHTML.replace("watch?v=","embed/"));
				});
			});
			document.querySelector(".video_list").querySelector(".video_item").click();
		}else{
			const grid=document.getElementById("talks_grid");
			if(grid)
			{
				grid.querySelectorAll(".talk_video").forEach(el=>{
					el.setAttribute("src",el.getAttribute("href").replace("watch?v=","embed/"));
				});
			}
		}
	},
	show_info:function(data,type)
	{
		//console.log("Info",data);
		document.querySelectorAll("[aria-data]").forEach(el=>{
			const id=el.getAttribute("aria-data");
			if(data[id])
				el.innerHTML=data[id];
			else{
				el.classList.add("hide");
			}
		});
	}
}
document.addEventListener("DOMContentLoaded", load, false);